define("discourse/plugins/waze-chat/discourse/components/waze-header-chat-icon", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="wz-header-chat-icon">
    <a
      class="icon"
      onclick={{this.toggleChatVisibility}}
      title={{I18n "waze.chat.header.title"}}
    >
      {{d-icon "w-chat" class=this.iconClasses}}
    </a>
    {{#if this.unreadCount}}
      <div class="wz-header-chat-icon-badge">{{this.badgeText}}</div>
    {{/if}}
  </li>
  */
  {
    "id": "eILP24Q5",
    "block": "[[[10,\"li\"],[14,0,\"wz-header-chat-icon\"],[12],[1,\"\\n  \"],[10,3],[14,0,\"icon\"],[15,\"onclick\",[30,0,[\"toggleChatVisibility\"]]],[15,\"title\",[28,[37,2],[\"waze.chat.header.title\"],null]],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"w-chat\"],[[\"class\"],[[30,0,[\"iconClasses\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"unreadCount\"]],[[[1,\"    \"],[10,0],[14,0,\"wz-header-chat-icon-badge\"],[12],[1,[30,0,[\"badgeText\"]]],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"li\",\"a\",\"I18n\",\"d-icon\",\"if\",\"div\"]]",
    "moduleName": "discourse/plugins/waze-chat/discourse/components/waze-header-chat-icon.hbs",
    "isStrictMode": false
  });
  // @ts-expect-error no types

  // @ts-expect-error no types

  // @ts-expect-error no types

  // @ts-expect-error no types

  const BADGE_MAX_NUMBER = 99;
  class WazeHeaderChatIcon extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "wazeChatSdk", [_service.inject]))();
    #wazeChatSdk = (() => (dt7948.i(this, "wazeChatSdk"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatSDK", [_tracking.tracked], function () {
      return null;
    }))();
    #chatSDK = (() => (dt7948.i(this, "chatSDK"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "unreadCount", [_tracking.tracked], function () {
      return 0;
    }))();
    #unreadCount = (() => (dt7948.i(this, "unreadCount"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("waze:chat:ready", this, this.wazeChatReady);
      this.appEvents.on("waze:chat:unread", this, this.wazeChatUnread);
    }
    willDestroy() {
      this.appEvents.off("waze:chat:ready", this, this.wazeChatReady);
      this.appEvents.off("waze:chat:unread", this, this.wazeChatUnread);
      super.willDestroy(...arguments);
    }
    wazeChatReady(sdk) {
      this.chatSDK = sdk;
      this.unreadCount = this.chatSDK.getUnreadChatCount();
    }
    static #_5 = (() => dt7948.n(this.prototype, "wazeChatReady", [_object.action]))();
    wazeChatUnread() {
      if (this.chatSDK) {
        this.unreadCount = this.chatSDK.getUnreadChatCount();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "wazeChatUnread", [_object.action]))();
    toggleChatVisibility() {
      if (this.chatSDK) {
        this.chatSDK.setVisibility(!this.chatSDK.getVisibility());
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "toggleChatVisibility", [_object.action]))();
    get badgeText() {
      return this.unreadCount > BADGE_MAX_NUMBER ? `${BADGE_MAX_NUMBER}+` : this.unreadCount;
    }
    get iconClasses() {
      const classes = ["w-chat"];
      if (!this.chatSDK) {
        classes.push("wz-header-chat-icon-disabled");
      }
      return classes.join(" ");
    }
  }
  _exports.default = WazeHeaderChatIcon;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WazeHeaderChatIcon);
});